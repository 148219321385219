import React, { Suspense, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Styled from 'styled-components'
import { useApiContext } from '../Hooks/useApiContext'
import { extractBorrowerAndCoborrowers } from '../Utils/apiExtractors'
import { handleScrollIntoView } from '../Utils/utils'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import ScrollToTop from '../Global/ScrollToTop'
import LoadingPage from '../Components/LoadingPage'
import LoginForm from './LoginForm.js'
import LandingPageTabs from './LandingPageTabs.js'
import { useLocation } from 'react-router-dom'
import { LandingPageBannerModern } from './LandingPageBannerModern.js'
import { PrepaymentBanner } from './PrepaymentBanner.js'
import { CommonQuestionsModern } from './CommonQuestionsModern.js'
import { CommonFormsModern } from './CommonFormsModern.js'

export const SIGN_IN = 'SIGN_IN'
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP'
export const CONFIRM_SIGN_IN = 'CONFIRM_SIGN_IN'

const RegistrationModal = React.lazy(() => import('../Common/RegistrationModal'));
const ForgotPassword = React.lazy(() => import('../Components/auth/ForgotPassword'));
const ConfirmSignIn = React.lazy(() => import('./auth/ConfirmSignIn'));
const ConfirmSignUp = React.lazy(() => import('./auth/ConfirmSignUp'));
const LandingPageCreateAccount= React.lazy(() => import('./LandingPageCreateAccount.js'));
const StyledAuthLayoutModern = Styled.div`
  margin: 50px auto;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .landing-image {
    min-height: 500px;
    background: url(landing-page.jpeg) no-repeat;
    background-size: cover;
    text-align: center;
    flex-wrap: nowrap;
  }

  .sign-in-block {
    background-color: rgba(48,114,179,0.7);
    border-left: solid 1px white;
    color: ${({ theme }) => theme.colors.white};
  }

  .blue-block {
    margin-top: 60px;

  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 40px 0;
    padding: 20px;
  }

  .chunk-loading{
    background:#ffff;
    border-radius:20px;
    margin-bottom:40px;
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .chunk-loading {
      width:100%;
      
    }
  }
  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .container {
      flex-direction: row-reverse;
    }

    .wrapper {
      position: relative;
      margin: 20px auto;
      bottom: -155px;
    }
 }
`

const AuthLayout =({ children, setAuthState, setAuthData, setUserInfo, setIsAuthenticated, authData, activeTab,authState }) => {
  const [modalShow, setModalShow] = useState(false)
  const [activeFormTab, setActiveFormTab] = useState(SIGN_IN)
  const [currentTab, setCurrentTab] = useState(SIGN_IN);
  const context = useApiContext()
  const { pathname } = useLocation()
  const isSignupVerificationPage =  activeFormTab === SIGN_IN && activeTab === CONFIRM_SIGN_UP
  const {
    // error: borrowerError,
    loading: loanLoading,
    data: loanData,
  } = context.loan
  const { data, loading } = useLoggedInUser()

  if (loanLoading || loading) {
    return <LoadingPage isHomePage={pathname==='/'} />
  }

  const [borrower] = loanData
    ? extractBorrowerAndCoborrowers(
      loanData,
      data?.attributes['custom:person_id'],
    )
    : [null]

  const toggleModal = () => {
    setModalShow(true)
  }
  return (
    <StyledAuthLayoutModern>
      <ScrollToTop />
      <Suspense fallback={null}>
      {activeFormTab===CREATE_ACCOUNT && <RegistrationModal
      // {modalShow && <RegistrationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        page="sign in"
      />}
      </Suspense>
      {data ? (
        <Container>
          <Row className="landing-image">
            <Col className="sign-in-block">
              <div className="blue-block"></div>
              <h1 className="welcome-title">
                Welcome <br />{' '}
                {loanData &&
                  borrower &&
                  `${borrower.firstName} ${borrower.lastName}`}
              </h1>
            </Col>
            <Col sm="7">
              <div className="main-content">{children}</div>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container>
            <div>
              {!isSignupVerificationPage && <LandingPageTabs currentTab={currentTab} setActiveFormTab={setActiveFormTab} setCurrentTab={setCurrentTab} />}
              {activeFormTab === SIGN_IN && !activeTab && (<LoginForm setActiveFormTab={setActiveFormTab} setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated} />)}
              <Suspense fallback={<div className='chunk-loading'> <LoadingPage /> </div>}>
              {activeFormTab === SIGN_IN && activeTab === CONFIRM_SIGN_UP && (<ConfirmSignUp setAuthState={setAuthState} authData={authData} />)}
              {activeFormTab === SIGN_IN && activeTab === CONFIRM_SIGN_IN && (<ConfirmSignIn setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated} authData={authData} />)}
              {activeFormTab === CREATE_ACCOUNT && (<LandingPageCreateAccount setModalShow={setModalShow} setActiveFormTab={setActiveFormTab} setCurrentTab={setCurrentTab} />)}
              {activeFormTab === FORGOT_PASSWORD && (<ForgotPassword setActiveFormTab={setActiveFormTab} />)}
              {/* {activeFormTab === FORGOT_PASSWORD && (<div className="chunk-loading"> <LoadingPage /> </div>)} */}
              </Suspense>
            </div>
            {!isSignupVerificationPage && <LandingPageBannerModern />}
          </Container>
          {!isSignupVerificationPage && <div className='wrapper'>
            <PrepaymentBanner />
          </div>}
          {!isSignupVerificationPage && <CommonQuestionsModern />}
          {!isSignupVerificationPage && <CommonFormsModern />}
        </>)}
    </StyledAuthLayoutModern>
  )
}

export default AuthLayout