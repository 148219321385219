import React from 'react'
import Styled from 'styled-components'

const StyledAuthContainerModern = Styled.div`
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 20px;
  margin-left: 15px;
  margin-bottom: 40px;

    .form-group {
        min-height: 50vh;
    }

    .form-section {
        background: rgba(255,255,255,.5);
        font-size: 20px;
    }

    .section-header {
        font-size: 30px;
        margin-bottom: 16px;
    }

    .SelectInput__selectInput___3efO4 {
      display: flex;
    }
    
    .SelectInput__selectInput___3efO4 > input {
      flex: 1;
      border-radius: 0 3px 3px 0 !important;
    }
    
    .SelectInput__selectInput___3efO4 > select {
      padding: 16px 20px 16px 16px;
      font-size: 14px;
      color: var(--deepSquidInk);
      background-color: #fff;
      background-image: none;
      border: 1px solid var(--lightGrey);
      border-right: none;
      border-radius: 3px 0 0 3px;
      box-sizing: border-box;
      margin-bottom: 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      flex-basis: 22%;
      width: 1%;
    
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 10px) calc(1em + 8px),
        calc(100% - 4px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
      background-size: 6px 5px, 6px 5px, 0px 1.5em;
      background-repeat: no-repeat;
    }

    .Input__input___3e_bf {
      display: block;
      width: 100%;
      padding: 16px;
      font-size: 14px;
      color: var(--input-color);
      background-color: var(--input-background-color);
      background-image: none;
      border: 1px solid var(--lightGrey);
      border-radius: 3px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
    
    .Input__input___3e_bf:disabled {
      color: #545454;
      background-color: var(--silver);
    }
    
    .Input__inputLabel___3VF0S {
      color: var(--input-color);
      font-size: 14px;
      margin-bottom: 8px;
    }
    
    .Input__label___23sO8 {
      color: var(--input-color);
    }
    
    .Input__radio___2hllK {
      margin-right: 18px;
      vertical-align: bottom;
    }
    
    @media only screen and (max-width: 599px) {
      .Input__input___3e_bf {
        font-size: 16px;
      }
    }
    
    @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
      .form-section {
        min-width: 0;
      }
    }

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      width: 610px;
      padding: 80px 40px;
    }
`

const AuthContainer = ({ header, children }) => {
  return (
      <StyledAuthContainerModern>
        <div className="Section__container___3YYTG form-group">
          <div className="Form__formContainer___1GA3x">
            <div className="Form__formSection___1PPvW form-section">
              <div className="Section__sectionHeader___2djyg section-header">
                <span>{header}</span>
              </div>
              {children}
            </div>
          </div>
        </div>
      </StyledAuthContainerModern>
    )
}

export default AuthContainer
